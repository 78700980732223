.appdevcont {
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #12131c;
  padding: 6.563rem 0;
  z-index: 5;
  @media (max-width: 599px) {
    padding: 4.688rem 0;
  }
  .head-appdev {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12.5rem;
    max-width: 880px;
    font-family: "Jost", sans-serif;
    @media (max-width: 1239px) {
      padding: 0 2rem;
    }
    @media (max-width: 599px) {
      padding: 0px 1rem;
      flex-direction: column;
      align-items: unset;
    }

    .headleft {
      width: 30%;
      order: 2;
      @media (max-width: 599px) {
        width: 100%;
        order: 1;
      }
      .label {
        color: #fdfaff;
      }
    }
    .headright {
      .title {
        font-size: 56px;
        line-height: 57.8px;
        font-weight: 400;
        color: #fbf8ff;
        margin: 0px 0 0px 0;
        @media (max-width: 1239px) {
          width: 80%;
        }
        @media (max-width: 599px) {
          font-size: 40px;
        }
      }
    }
  }
  .container {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    max-width: 880px;
    padding: 0 12.5rem;

    @media (max-width: 1239px) {
      width: 90%;
      flex-direction: column;
      padding: 0 2rem;
    }
    @media (max-width: 599px) {
      padding: 0px 1rem;
    }
    .center {
      display: flex;
      width: 20%;
      @media (max-width: 1239px) {
        width: 100%;
      }

      .buttonslider {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        @media (max-width: 1239px) {
          flex-direction: row;
          gap: 0px;
        }
        .buttoncontainer {
          width: 100%;
          .garisbutton {
            width: 100%;
            border: none;
            height: 2px;
            margin-top: 9px;
            cursor: pointer;
            transition: background-image 0.3s ease;
            background-color: #5c5e6f;
            &.active {
              background-color: #95d1ff;
            }
          }
          .buttonapp {
            font-family: "Jost", sans-serif;
            font-size: 19px;
            font-weight: 400;
            width: 100%;
            color: #a1a1b4;
            fill: #a1a1b4;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0px;
            margin: 0px;
            border: none;
            gap: 4px;
            background-color: transparent;
            transition: background-image 0.3s ease;
            &.active {
              color: #95d1ff;
              fill: #95d1ff;
            }
            @media (max-width: 1239px) {
              font-size: 16px;
            }
            @media (max-width: 599px) {
              font-size: 14px;
            }
          }
        }
      }
    }
    .slidercenter {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1239px) {
        width: 100%;
      }
      .sliderapp {
        width: 100%;
        .slick-slide {
          > div {
            margin-inline: 15px;
            // automatically generated 'div' by 'react-slick'
            //  space between slides / 2
          }
        }
        .slick-slider {
          margin-inline: -15px;
          .slick-list {
            overflow: hidden;
            // .slick-track {
            // }
          }
        }
        .slideritem {
          display: flex;
          flex-direction: row;
          border-radius: 25px;
          align-items: center;
          gap: 15px;
          width: 100%;
          z-index: 1;
          @media (max-width: 599px) {
            flex-direction: column;
            border-radius: 16px;
            margin-top: 10px;
            gap: 28px;
          }
          .slidercontent {
            width: calc(50% - 7.5px);
            margin: 25px 0 25px 25px;
            @media (max-width: 599px) {
              width: 100%;
              margin: 0;
            }
            .title {
              font-family: "Jost", sans-serif;
              font-size: 28px;
              font-weight: 400;
              color: #c1c7ff;
              @media (max-width: 599px) {
                font-size: 23px;
                margin: 1rem 1.5rem 0 1rem;
              }
            }
            .subtitle {
              margin: 15px 0 15px 0;
              font-family: "Jost", sans-serif;
              font-weight: 400;
              color: #fdfaff94;
              @media (max-width: 599px) {
                font-size: 16px;
                line-height: 23.12px;
                margin: 0rem 1.5rem 0 1rem;
              }
            }
            .buttonwa {
              display: flex;
              background-color: #c1c7ff;
              gap: 15px;
              height: 41px;
              padding: 9px 37px;
              justify-content: center;
              border-radius: 9px;

              border: none;
              color: #001827;
              overflow: hidden;
              font: 16px "Jost", sans-serif;
              line-height: 23.12px;
              font-weight: 500;
              cursor: pointer;
              align-self: center;
              text-align: center;
              &:hover {
                > div {
                  display: flex;
                  flex-direction: column;
                  .primary-arrow {
                    transform: translate3d(130%, -150%, 0);
                  }
                  .secondary-arrow {
                    transform: translate3d(0%, -150%, 0);
                  }
                }
              }
              > div {
                display: flex;
                gap: 10px;
                height: 100px;
                flex-direction: column;
                .primary-arrow {
                  transition: all 0.25s ease-out;
                }
                .secondary-arrow {
                  transition: all 0.25s ease-out;
                  transform: translate3d(-130%, 0%, 0);
                }
              }
              @media (max-width: 599px) {
                margin: 1rem 1.5rem 0 1rem;
              }
            }
          }
          .sliderimage {
            width: calc(50% - 7.5px);
            margin: 25px 25px 25px 0;
            z-index: 5;
            display: flex;
            justify-content: center;
            .imgsl {
              width: 100%;
              height: 326px;
              object-fit: contain;
              @media (max-width: 599px) {
                height: 309px;
              }
            }
            @media (max-width: 599px) {
              width: 100%;
              margin: 0;
              height: 100%;
              margin-bottom: 1rem;
            }
          }
        }
        #slider-webapp {
          background-image: url(../../media/bg-web-gradient.png);
          background-size: cover;
          background-repeat: no-repeat;
        }
        #slider-mobileapp {
          background-image: url(../../media/bg-mobile-gradient.png);
          background-size: cover;
          background-repeat: no-repeat;
        }
        #slider-desktopapp {
          background-image: url(../../media/bg-desktop-gradient.png);
          background-size: cover;
          background-repeat: no-repeat;
        }
        .mobilebutton {
          @media (min-width: 599px) {
            display: none;
          }
          padding-bottom: 20px;
          .buttonapp {
            font-family: "Jost", sans-serif;
            font-size: 19px;
            font-weight: 400;
            color: white;
            display: flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            border: none;
            border-radius: 6px;
            background-color: #5c5e6f;
            transition: background-image 0.3s ease;
          }
          .buttontext {
            height: 130px;
            margin-top: 15px;
            font-family: "Jost", sans-serif;
            font-size: 18px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
