.webdevcont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #3a4379;
  padding: 6.563rem 0;
  @media (max-width: 599px) {
    padding: 4.688rem 0;
  }
  .head-webdev {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12.5rem;
    max-width: 880px;
    font-family: "Jost", sans-serif;
    @media (max-width: 1239px) {
      padding: 0 2rem;
    }
    @media (max-width: 599px) {
      padding: 0px 1rem;
      flex-direction: column;
      align-items: unset;
    }

    .headleft {
      width: 30%;
      order: 2;
      @media (max-width: 599px) {
        width: 100%;
        order: 1;
      }
      .label {
        color: #e4e1e9;
      }
    }
    .headright {
      .title {
        font-size: 56px;
        line-height: 57.8px;
        font-weight: 400;
        color: #dee0ff;
        margin: 0px 0 0px 0;
        @media (max-width: 1239px) {
          width: 80%;
        }
        @media (max-width: 599px) {
          font-size: 40px;
        }
      }
    }
  }
  .showcase-content-webdev {
    width: 100%;
    margin: 40px 0;
    position: relative;
    .slider {
      display: flex;
      justify-content: center;
      gap: 15px;
      position: relative;

      @media (max-width: 1239px) {
        padding: 0;
      }
      @media (max-width: 599px) {
        padding: 0 1rem;
      }
      div {
        padding: 0px;
        img {
          border-radius: 0;

          /* Reset inherited border-radius */
        }
      }
      .slider-item1 {
        width: 295px;
        height: fit-content;
        z-index: 2;
        position: absolute;
        left: 0;
        @media (max-width: 1239px) {
          display: none;
        }
        @media (min-width: 1240px) and (max-width: 1490px) {
          left: -100px;
          width: 260px;
        }
        .slick-slide {
          /* Sesuaikan tinggi slide */
          height: 182px;
          @media (min-width: 1669px) {
            height: 100%;
          }
        }
        img {
          @media (min-width: 1240px) and (max-width: 1490px) {
            height: 200px;
            width: 200px;
          }
        }

        div {
          border-radius: 0px 16px 16px 0px;
        }
      }
      .slider-item2 {
        width: 880px;
        position: relative;
        @media (max-width: 1239px) {
          width: 100%;
        }
        @media (max-width: 599px) {
          width: 100%;
        }
        .brand {
          background-color: #fbf8ff;
          border-radius: 9px;
          padding: 9px;
          height: fit-content;
          width: fit-content;
          position: absolute;
          top: 20px;
          margin-left: 20px;
          @media (max-width: 1239px) {
            display: none;
          }
        }
        .slick-track {
          @media (max-width: 1239px) {
            display: flex;
          }
        }

        .slick-list {
          @media (max-width: 1239px) {
            overflow: visible;
            margin: 0;
          }
        }
        .slick-slide {
          text-align: center;
          color: white;
          height: 550px;
          @media (min-width: 1669px) {
            height: 650px;
          }
          @media (max-width: 1239px) {
            width: 330px;
            height: auto;
          }
          @media (max-width: 599px) {
            width: 343px;
          }
          > div {
            @media (max-width: 1239px) {
              margin-inline: 10px;
            }
            @media (max-width: 599px) {
              margin-inline: 5px;
            }
          }
        }

        div {
          border-radius: 16px;
          .background-img {
            position: relative;
            width: 880px;
            z-index: -20;
            object-fit: cover;

            @media (min-width: 1669px) {
              height: 650px;
            }
            @media (max-width: 1239px) {
              border-radius: 16px;
              width: 100%;
            }
          }
        }
        .slider-container {
          @media (max-width: 1239px) {
            position: relative;
          }
        }
        .slider-content {
          position: absolute;
          border-radius: 0;
          top: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          margin: 20px;
          width: 840px;
          max-width: 880px;
          justify-content: space-between;
          align-items: start;
          overflow: hidden;
          z-index: 20;

          @media (max-width: 1239px) {
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            width: 100%;
            margin: 0px;
            gap: 15px;
          }
          .slider-head-wrapper {
            display: flex;
            width: 100%;
            justify-content: end;
          }

          .rating {
            display: flex;
            background-color: #fbf8ff;
            border-radius: 9px;
            padding: 9px;
            height: fit-content;
            transform: translateY(-150%);
            transition: all 0.25s ease-in-out;
            font-family: "Jost", sans-serif;
            gap: 4px;
            justify-content: space-between;
            @media (max-width: 1239px) {
              transform: translateY(0);
              order: 2;
              width: 100%;
            }
            @media (max-width: 599px) {
            }
            .rating-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;
              padding: 0 13px;
              @media (max-width: 1239px) {
                padding: 0;
              }
              div {
                @media (max-width: 1239px) {
                  width: 41px;
                  height: 41px;
                }
              }

              .CircularProgressbar-path {
                stroke: #525a92;
                @media (max-width: 1239px) {
                  width: 41px;
                  height: 41px;
                }
              }
              .CircularProgressbar-trail {
                stroke: #525a926b;
              }
              .CircularProgressbar-text {
                fill: #525a92;
              }
              .CircularProgressbar-background {
                fill: green;
              }
              .rating-label {
                font-size: 12px;
                color: #131318;
              }
            }
          }
          .slider-content-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 40%;
            @media (max-width: 1239px) {
              order: 1;
              width: 100%;
            }
            .slider-content-label {
              background: #fbf8ff;
              color: #12131c;
              text-align: start;
              border-radius: 9px;
              padding: 9px;
              width: 100%;
              font-family: "Jost", sans-serif;
              @media (max-width: 1239px) {
                width: 100%;
                background: transparent;
                color: #fbf8ff;
              }
              .title {
                font-size: 40px;
                margin: 0 0 4px 0;
                font-weight: 400;
                @media (max-width: 1239px) {
                  font-size: 28px;
                }
              }

              .tags {
                display: flex;
                margin: 0;
                padding: 0;
                font-size: 12px;
                gap: 6px;
                list-style-position: inside;
                li {
                  margin-right: 6px;
                }
              }
            }
          }
        }
        .slider-content:hover {
          .rating {
            transform: translateY(0);
          }
        }
        .showcase-content-arrow {
          display: flex;
          gap: 0 15px;
          position: absolute;
          bottom: 20px;
          right: 20px;
          @media (max-width: 1239px) {
            display: none;
          }
          @media (max-width: 599px) {
            margin-top: 15px;
            justify-content: flex-end;
            width: 100%;
          }

          .left-arrow {
            display: flex;
            position: relative;
            rotate: 180deg;
            border: 1px solid #fdfaff;
            border-radius: 4px;
            width: 27px;
            height: 24px;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            cursor: pointer;
            .inner-left-arrow {
              position: absolute;
              transition: all 0.25s ease-out;
              left: 5px;
            }
            .outer-left-arrow {
              position: absolute;
              right: 30px;
              transition: all 0.25s ease-out;
            }
          }
          .left-arrow:hover {
            .inner-left-arrow {
              left: 30px;
            }
            .outer-left-arrow {
              right: 5px;
            }
          }
          .right-arrow {
            display: flex;
            position: relative;
            border: 1px solid #fdfaff;
            border-radius: 4px;
            width: 27px;
            height: 24px;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            cursor: pointer;
            .inner-right-arrow {
              position: absolute;
              transition: all 0.25s ease-out;
              left: 5px;
            }
            .outer-right-arrow {
              position: absolute;
              right: 30px;
              transition: all 0.25s ease-out;
            }
          }
          .right-arrow:hover {
            .inner-right-arrow {
              left: 30px;
            }
            .outer-right-arrow {
              right: 5px;
            }
          }
        }
      }
      .slider-item3 {
        width: 295px;
        position: absolute;
        z-index: 2;
        right: 0;

        @media (max-width: 599px) {
          display: none;
        }
        @media (max-width: 1239px) {
          width: 50%;
          margin-right: 0%;
          margin-left: 10px;
          display: none;
        }
        @media (min-width: 1240px) and (max-width: 1490px) {
          right: -100px;
          width: 260px;
        }
        .slick-slide {
          /* Sesuaikan tinggi slide */
          height: 182px;
          @media (min-width: 1669px) {
            height: 100%;
          }
        }
        div {
          border-radius: 16px 0 0 16px;
          @media (max-width: 1239px) {
            border-radius: 16px;
          }
        }
        img {
          @media (min-width: 1240px) and (max-width: 1490px) {
            height: 200px;
          }
        }
      }
    }
    .read-more {
      position: absolute;
      right: 1%;
      bottom: 1%;
      @media (max-width: 1239px) {
        position: relative;
        margin: 0 auto;
        margin-top: 40px;
      }
    }
    button {
      display: flex;
      padding: 9px;
      color: #001827;
      position: relative;
      overflow: hidden;
      font-family: "jost", sans-serif;
      font-weight: 500;
      height: 105px;
      background-color: #95d1ff;
      border: none;
      font-size: 16px;
      cursor: pointer;
      border-radius: 9px;
      @media (max-width: 1239px) {
        height: 41px;
        gap: 15px;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        gap: 25px;
        height: 100px;
        transition: all 0.25s ease-out;
        @media (max-width: 1239px) {
          margin: 0;
        }
        .primary-arrow {
          transition: all 0.25s ease-out;
        }
        .secondary-arrow {
          transition: all 0.25s ease-out;
          transform: translate3d(-150%, 0, 0);
          @media (max-width: 1239px) {
            transform: translate3d(-220%, 0, 0);
          }
        }
      }
    }

    button:hover {
      div {
        overflow: hidden;
        .primary-arrow {
          transition: all 0.25s ease-out;
          transform: translate3d(200%, -250%, 0);
        }
        .secondary-arrow {
          transition: all 0.25s ease-out;
          transform: translate3d(0, -160%, 0);
          @media (max-width: 1239px) {
            transform: translate3d(0, -230%, 0);
          }
        }
      }
    }
  }
  .service-pricing {
    display: flex;
    padding: 2.5rem 2rem 0 2rem;
    position: relative;
    overflow: hidden;
    @media (max-width: 599px) {
      padding: 2.5rem 1rem 0 1rem;
    }
    .container-pricing {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      background-color: #232c61;
      width: 80vw;
      border-radius: 32px;
      position: relative;
      align-items: center;
      justify-content: center;
      z-index: 1;
      overflow: hidden;
      @media (max-width: 1239px) {
        width: 90vw;
      }
      @media (max-width: 599px) {
        border-radius: 16px;
      }
      .line-1 {
        position: absolute;
        top: -90px;
        left: -60px;
        z-index: 2;
        @media (max-width: 1239px) {
          top: -100px;
          left: -50px;
        }
      }
      .table-pricing {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 880px;
        z-index: 4;
        padding: 4.063rem 0 2.5rem 0;
        @media (max-width: 1239px) {
          width: 90%;
        }
        @media (max-width: 599px) {
          padding: 2.875rem 0;
        }

        .pricing {
          width: calc(100% / 3 - (20px - 20px / 3));
          border-radius: 13px;
          z-index: 3;
          font-family: "Jost", sans-serif;
          @media (max-width: 1239px) {
            width: 100%;
          }
          @media (max-width: 599px) {
            width: 100%;
          }
          .label {
            display: flex;
            justify-content: center;
            padding: 6px 0;
            border-radius: 13px 13px 0 0;
            gap: 6px;
            small {
              font-size: 14px;
              font-weight: 500;
            }
          }
          .content {
            border-radius: 0 0 13px 13px;
            .content-head {
              .content-hero {
                display: flex;
                justify-content: center;
                font-size: 23px;
                height: 107px;
                .title {
                  margin: 0;
                  padding: 15px;
                  text-transform: uppercase;
                  color: #e4e1e9;
                  font-weight: 400;
                  font-size: 23px;
                  height: fit-content;
                }
              }
            }
            .content-prices {
              display: flex;
              flex-direction: column;
              padding: 0 13px;
              align-items: center;
              background-color: #fbf8ff;
              border-radius: 0 0 10px 10px;
              .price-box {
                display: flex;
                flex-direction: column;
                padding: 9px 13px;
                align-items: center;
                border: 1px solid #1a1b2426;
                border-radius: 9px;
                background-color: #fbf8ff;
                width: 90%;
                margin-top: -50px;
                .overline {
                  color: #1b1b21;
                }
                .price {
                  display: flex;
                  gap: 2px;
                  .currency {
                    color: #1a1b24;
                    margin: 9px 0;
                  }
                  .number {
                    margin: 0;
                    color: #1a1b24;
                    font-size: 40px;
                    font-weight: 400;
                  }
                }
                .subscription {
                  color: #1b1b21e4;
                  border: 1px solid#1B1B21;
                  border-radius: 4px;
                  padding: 4px;
                }
              }
              .pricebutton {
                margin: 15px 0;
                width: 100%;
                .buttonwa {
                  display: flex;
                  position: relative;
                  background-color: #266489;
                  gap: 15px;
                  width: 100%;
                  height: 41px;
                  padding: 9px 13px;
                  justify-content: center;
                  border-radius: 9px;
                  border: none;
                  color: #ffffff;
                  overflow: hidden;
                  font: 16px "Jost", sans-serif;
                  line-height: 23.12px;
                  font-weight: 500;
                  cursor: pointer;
                  text-align: center;
                  &:hover {
                    > div {
                      display: flex;
                      flex-direction: column;
                      .primary-arrow {
                        transform: translate3d(130%, -150%, 0);
                      }
                      .secondary-arrow {
                        transform: translate3d(0%, -150%, 0);
                      }
                    }
                  }
                  > div {
                    display: flex;
                    gap: 10px;
                    flex-direction: column;
                    height: 100px;
                    .primary-arrow {
                      transition: all 0.25s ease-out;
                    }
                    .secondary-arrow {
                      transition: all 0.25s ease-out;

                      transform: translate3d(-130%, 0%, 0);
                    }
                  }
                }
              }
              .benefit-lists {
                display: flex;
                flex-direction: column;
                gap: 9px;
                width: 100%;
                margin-bottom: 15px;
                color: #1a1b24;
                .benefit {
                  display: flex;
                  justify-content: start;
                  width: 100%;
                  gap: 6px;
                }
              }
            }
          }
        }
        #e-commerce {
          .label {
            color: #000000;
            background-color: #eee58b;
            border-top: 3px solid #eee58b;
            border-left: 3px solid #eee58b;
            border-right: 3px solid #eee58b;
          }
          .content {
            border: 3px solid #eee58b;
            .content-hero {
              background: rgb(201, 0, 34);
              background: linear-gradient(
                90deg,
                rgba(201, 0, 34, 1) 0%,
                rgba(89, 0, 201, 1) 50%,
                rgba(0, 10, 80, 1) 100%
              );
            }
          }
        }
        #landing-page {
          .label {
            color: #370001;
            background-color: #ffdad6;
            border-top: 3px solid #ffdad6;
            border-left: 3px solid #ffdad6;
            border-right: 3px solid #ffdad6;
          }
          .content {
            border: 3px solid #ffdad6;
            .content-hero {
              background: rgb(150, 0, 200);
              background: linear-gradient(
                90deg,
                rgba(150, 0, 200, 1) 31%,
                rgba(83, 4, 146, 1) 62%,
                rgba(0, 10, 80, 1) 100%
              );
            }
          }
        }
        #company-profile {
          .label {
            color: #000a50;
            background-color: #dee0ff;
            border-top: 3px solid #dee0ff;
            border-left: 3px solid #dee0ff;
            border-right: 3px solid #dee0ff;
          }
          .content {
            border: 3px solid #dee0ff;
            .content-hero {
              background: rgb(52, 6, 122);
              background: linear-gradient(
                90deg,
                rgba(52, 6, 122, 1) 0%,
                rgba(0, 10, 80, 1) 100%
              );
            }
          }
        }
        .disclaimer {
          font-family: "Jost", sans-serif;
          font-size: 14px;
          color: #fdfaffad;
          text-align: center;
        }
      }

      .line-2 {
        position: absolute;
        bottom: -10px;
        right: -180px;
        z-index: 2;
        @media (max-width: 1239px) {
          right: -180px;
          bottom: -20px;
        }
      }
    }
  }
}
