/* Container untuk loader */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 1.2rem;
  color: #555;
}

/* Animasi spinner */
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

/* Keyframes untuk animasi putaran */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Post container */
.post-container {
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  font-family: "Jost", sans-serif;
  color: #333;
  line-height: 1.6;
  .container-meta {
    width: 880px;
    margin: 25px 0;
  }
  .post-image {
    width: 1216px;
    height: 521px;
    object-fit: cover;
    border-radius: 32px;
    margin: 15px 0;
  }

  .post-title {
    font-size: 3.5rem;
    font-weight: 400;
    margin: 0 0 25px 0;
    color: #525a92;
    text-align: center;
  }

  .post-meta {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    font-size: 0.9rem;
    border-bottom: solid 1px #000000;
    color: #1b1b21;
    padding-bottom: 6px;
    p {
      margin: 0;
    }
    .post-address {
      display: flex;
      gap: 40px;
    }
  }

  .post-content {
    margin: 40px 0;
    font-size: 1rem;
    text-align: justify;
    width: 880px;
    color: #1b1b21;
    p {
      margin-bottom: 15px;
    }

    a {
      color: #007bff;
      text-decoration: underline;
    }
  }

  /* Social Share Section */
  .post-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 880px;
    padding-top: 6px;
    border-top: solid 1px #1b1b21;
    p {
      margin: 0;
      color: #1b1b219f;
    }
    span {
      margin-left: 15px;
      color: #1b1b21;
    }
    .social-share {
      display: flex;
      align-items: center;
      justify-content: center;
      .social-btn {
        display: inline-block;
        margin: 5px;
        font-size: 14px;
        color: white;
        text-decoration: none;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
        background-color: transparent;
        border: none;
      }
    }
  }
}

/* 📱 RESPONSIVE STYLING */

/* Tablet */
@media (max-width: 1239px) {
  .post-container {
    padding: 15px;
    .container-meta {
      width: 90%;
    }
    .post-title {
      font-size: 1.8rem;
    }

    .post-meta {
      font-size: 0.85rem;
    }
    .post-image {
      width: 90%;
      height: 512px;
    }

    .post-content {
      font-size: 0.95rem;
      width: 90%;
    }

    .post-bottom {
      width: 90%;
    }
    .social-share {
      .social-btn {
        padding: 8px 12px;
        font-size: 13px;
      }
    }
  }
}

/* Mobile */
@media (max-width: 599px) {
  .post-container {
    padding: 10px;
    img {
      width: 100%;
      height: auto;
    }
    .container-meta {
      margin: 17px 0;
    }
    .post-title {
      font-size: 2.5rem;
      text-align: start;
      margin: 0 0 17px 0;
    }
    .post-image {
      border-radius: 16px;
    }

    .post-meta {
      font-size: 0.8rem;
      .post-address {
        gap: 15px;
      }
    }

    .post-content {
      margin: 28px 0;
      font-size: 0.9rem;
      text-align: justify;
    }

    .social-share {
      display: flex;

      .social-btn {
        width: 90%;
        max-width: 300px;
        padding: 0;
        margin: 5px 0;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
