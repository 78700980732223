.about-container {
  display: flex;
  justify-content: center;
  text-align: start;
  gap: 20px;
  .container-center {
    display: flex;
    justify-content: center;
    text-align: start;
    gap: 20px;
    max-width: 880px;
    padding: 6.563rem 12.5rem;
    @media (max-width: 1239px) {
      flex-direction: column;
      padding: 6.563rem 2rem;
    }
    @media (max-width: 599px) {
      padding: 4.688rem 1rem;
    }
    .container-img {
      width: 50%;
      @media (max-width: 1239px) {
        width: 100%;
      }

      img {
        width: 100%;
        border-radius: 20px;
        height: 100%;
        object-fit: cover;
      }
    }
    .container-description {
      width: 50%;
      @media (max-width: 1239px) {
        width: 100%;
      }
      .center {
        display: flex;
        flex-direction: column;

        .abouttitle {
          font-family: "Jost", sans-serif;
          color: #0027c0;
          font-size: 40px;
          @media (max-width: 599px) {
            width: 100%;
            font-size: 28px;
          }
        }
        .aboutdes {
          font-family: "Jost", sans-serif;
          margin: 9px 0 25px 0;
          color: #1a1b24;
          @media (max-width: 599px) {
            margin: 9px 0 17px 0;
          }
        }
        .aboutlabel {
          font-family: "Jost", sans-serif;
          color: #28292f;
          font-size: 12px;
        }
        .abouttools {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          margin: 9px 0 30px 0;
          @media (max-width: 599px) {
            margin: 7px 0 45px 0;
            gap: 7px;
          }
        }
        .tools-img {
          border: 1px solid #1a1b2440;
          border-radius: 9px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 54px;
          height: 54px;
          img {
            width: 30px;
          }
        }
        .buttondiv {
          display: flex;
          .btn-welcome {
            display: flex;
            position: relative;
            background-color: #004769;
            gap: 15px;
            width: 149px;
            height: 41px;
            padding: 9px 15px;
            justify-content: center;
            border-radius: 9px;
            border: none;
            color: #ffffff;
            overflow: hidden;
            font: 16px "Jost", sans-serif;
            line-height: 23.12px;
            font-weight: 500;
            cursor: pointer;
            align-self: center;
            text-align: center;

            > div {
              display: flex;
              gap: 10px;
              flex-direction: column;
              height: 100px;
              .primary-arrow {
                transition: all 0.25s ease-out;
              }
              .secondary-arrow {
                transition: all 0.25s ease-out;
                transform: translate3d(-130%, 0%, 0);
              }
            }
          }
          .btn-welcome:hover {
            > div {
              display: flex;
              flex-direction: column;
              .primary-arrow {
                transform: translate3d(130%, -150%, 0);
              }
              .secondary-arrow {
                transform: translate3d(0%, -150%, 0);
              }
            }
          }
        }
      }
    }
  }
}
