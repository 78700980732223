.blog-section {
  a {
    text-decoration: none;
  }
  .blog-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #12131c;
    height: 50vh;
    gap: 25px;
    font-family: "jost", sans-serif;
    @media (min-width: 1281px) {
      padding: 0 12.5rem;
    }
    @media (max-width: 1280px) {
      padding: 0 8.5rem;
    }
    @media (max-width: 1024px) {
      padding: 0 2rem;
    }
    @media (max-width: 599px) {
      padding: 0px 1rem;
      gap: 11px;
    }
    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 880px;
      @media (max-width: 1239px) {
        width: 100%;
      }
    }
    .headline {
      margin: 0;
      font-size: 70px;
      color: #c1c7ff;
      font-weight: 400;
      @media (max-width: 599px) {
        font-size: 56px;
      }
    }
    .description {
      margin: 0;
      width: 50%;
      align-self: end;
      color: #fdfaff;
      @media (max-width: 599px) {
        width: 100%;
      }
    }
  }
  .blog-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1440px) {
      padding: 4.063rem 12.5rem;
    }
    @media (max-width: 1439px) {
      padding: 4.063rem 8.5rem;
    }
    @media (max-width: 1239px) {
      padding: 4.063rem 2rem;
    }
    @media (max-width: 599px) {
      padding: 2.875rem 1rem;
    }
    .card-latest-news {
      width: 880px;
      background-color: #314ef50a;
      display: flex;
      gap: 9px;
      border-radius: 9px;
      @media (max-width: 1239px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .latest-image {
        background-color: #d9d9d9;
        height: 323px;
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
        }
        .img-latest-news {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .latest-description {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 40px;
        padding: 9px 9px 9px 0;
        justify-content: space-between;
        font-family: "Jost";
        @media (max-width: 768px) {
          width: 96%;
          padding: 7px;
        }
        .desc-top {
          display: flex;
          flex-direction: column;
          gap: 6px;
          .category {
            color: #0027c0;
            font-size: 14px;
            @media (max-width: 599px) {
              font-size: 12px;
            }
          }
          .title {
            font-size: 28px;
            font-weight: 400;
            margin: 0px;
            color: #1a1b24;
            @media (max-width: 599px) {
              font-size: 23px;
            }
          }
        }
        .desc-bottom {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          .author-container {
            width: calc(50% - 10px);
            display: flex;
            flex-direction: column;
            justify-content: end;
            .date {
              color: #1a1b249b;
              font-size: 16px;
            }
            .author {
              color: #1a1b24;
              font-style: normal;
            }
          }
          .latest-news-btn {
            width: 30%;
            @media (max-width: 904px) {
              width: 40%;
            }
            @media (max-width: 599px) {
            }
          }
        }
      }
    }
  }
  .blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1440px) {
      padding: 4.063rem 12.5rem;
    }
    @media (max-width: 1439px) {
      padding: 4.063rem 8.5rem;
    }
    @media (max-width: 1239px) {
      padding: 4.063rem 2rem;
    }
    @media (max-width: 599px) {
      padding: 2.875rem 1rem;
    }
    .categories {
      display: flex;
      gap: 10px;
      margin: 0 0 40px 0;
      padding: 10px 0;
      width: 880px;
      overflow-x: auto; // Scroll horizontal
      white-space: nowrap; // Mencegah kategori turun ke bawah
      scrollbar-width: none; // Sembunyikan scrollbar di Firefox
      &::-webkit-scrollbar {
        display: none; // Sembunyikan scrollbar di Chrome/Safari
      }
      @media (max-width: 1239px) {
        width: 100%;
      }
      @media (max-width: 599px) {
        margin: 0 0 28px 0;
      }
      li {
        list-style: none;
        button {
          padding: 6px 15px;
          font: 16px "Jost", sans-serif;
          background-color: transparent;
          text-transform: uppercase;
          color: #1a1b24;
          border: 1px solid transparent;
          border-radius: 9px;
          cursor: pointer;
          transition: all 0.25s ease-out;
          &:hover {
            border: 1px solid #1a1b24;
          }
          &:focus {
            border: 1px solid transparent;
            color: #fdfaff;
            background-color: #1a1b24;
          }
        }
        .active {
          border: 1px solid transparent;
          color: #fdfaff;
          background-color: #1a1b24;
        }
      }
    }
    .posts {
      width: 880px;
      @media (max-width: 1239px) {
        width: 100%;
      }
      .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px; /* Sesuaikan tinggi jika diperlukan */
      }

      .loader {
        width: 50px;
        height: 50px;
        border: 5px solid rgba(0, 0, 0, 0.1);
        border-top-color: #333; /* Warna utama loader */
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      .posts-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @media (max-width: 599px) {
          grid-template-columns: 1fr;
        }
      }
      .post-card {
        display: flex;
        flex-direction: column;
        .card-read-more {
          position: absolute;
          bottom: 9px;
          right: 9px;
          transform: translateY(150%);
          transition: all 0.35s ease-in-out;
          @media (max-width: 1239px) {
            display: none;
          }
        }
        &:hover {
          .card-read-more {
            transform: translateY(0);
          }
        }

        .post-info {
          display: flex;
          justify-content: space-between;
          padding-bottom: 6px;
          margin-bottom: 9px;
          border-bottom: 1px solid #000000;
          font-family: "Jost";
          font-size: 14px;
          color: #1a1b24;
          text-transform: uppercase;
        }
        .container-image {
          height: 280px;
          position: relative;
          overflow: hidden;
          .post-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
          }
        }

        .post-desc {
          text-decoration: none;
          display: flex;
          flex-direction: column;
          font-family: "Jost", sans-serif;
          gap: 15px;
          margin-top: 6px;
          .post-title {
            margin: 0;
            font-size: 19px;
            color: #1a1b24;
          }
          .post-author {
            font-size: 14px;
            color: #1a1b24;
            font-style: normal;
            text-transform: capitalize;
          }
        }
      }
      .pagination {
        display: flex;
        justify-content: center;
        gap: 5px;
        margin-top: 40px;
        .pagination-item {
          font-family: "Jost", sans-serif;
          padding: 4px 10px;
          border: 1px solid transparent;
          color: #1a1b24;
          border-radius: 6px;
          transition: all 0.25s ease-out;
          &:hover {
            border: 1px solid #1a1b24;
          }
        }
        .active {
          background-color: #1a1b24;
          color: #fbf8ff;
        }
      }
    }
  }
}
