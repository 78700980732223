.sosmedcont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #93000a;
  padding: 6.563rem 0;
  font-family: "Jost", sans-serif;
  @media (max-width: 599px) {
    padding: 4.688rem 0;
  }
  .head-sosmed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 880px;
    padding: 0 12.5rem;

    @media (max-width: 1239px) {
      padding: 0 2rem;
    }
    @media (max-width: 599px) {
      padding: 0px 1rem;
      flex-direction: column;
      align-items: unset;
    }

    .headleft {
      width: 30%;
      order: 2;
      @media (max-width: 599px) {
        width: 100%;
        order: 1;
      }
      .label {
        color: #e4e1e9;
      }
    }
    .headright {
      .title {
        font-size: 56px;
        line-height: 57.8px;
        font-weight: 400;
        color: #ffdad6;
        margin: 0px 0 0px 0;
        @media (max-width: 1239px) {
          width: 80%;
        }
        @media (max-width: 599px) {
          font-size: 40px;
        }
      }
    }
  }
  .showcase-content {
    width: 100%;
    margin: 40px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .slider {
      width: 100%;
      z-index: 5;

      // .slick-slider {
      //   transform: translateX(19%);
      // }

      .slick-slide {
        width: 295px;
        > div {
          // automatically generated 'div' by 'react-slick'
          margin-inline: 10px; //  space between slides / 2
        }
      }
      .card {
        display: flex;
        border-radius: 12px;
        color: #ffffff;
        min-height: 524px;
        font-size: 12px;
        align-items: center;

        .card-top {
          display: flex;
          height: 68px;
          align-items: center;
          justify-content: center;
          gap: 6px;
          padding: 0 23px;
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            color: #1a1b24;
          }
          div > li {
            list-style-type: none;
            text-align: start;
          }
          li:nth-child(2) {
            opacity: 0.65;
          }

          .image-top-physical {
            width: 182px;
            height: 30px;
            object-fit: contain;
          }
          .image-top-digital {
            width: 38px;
          }
        }
        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          border-top: 3px dashed #1a1b24;
          border-bottom: 3px dashed #1a1b24;
          height: 388px;
          .image-body-physical {
            width: 255px;
            height: 348px;
            object-fit: cover;
            margin: 20px auto;
            border-radius: 16px;
          }
          .image-body-digital {
            width: 100%;
            margin: 0 auto;
          }
          .image-body-logo {
            width: 122px;
          }
          .info-view {
            display: flex;
            gap: 0 12px;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0;
            padding: 0 20px;
            color: #1a1b24;
            ul {
              list-style: none;
              margin: 0;
              padding: 0;
            }
            li {
              list-style-type: none;
            }
            li:nth-child(1) {
              font-size: 23px;
            }
          }
        }
        .card-bottom {
          display: flex;
          height: 68px;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          color: #1a1b24;
          fill: #1a1b24;

          .card-info-physical-1 {
            text-align: start;
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style-type: none;
              }
            }
          }
          .card-info-physical-2 {
            text-align: right;
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style-type: none;
              }
            }
          }
          .card-info-digital {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 2px 0;
          }
        }
      }
      .card-mabowl {
        background-color: #54dbc4;
      }
      .card-proflight {
        background-color: #c0c7ff;
      }
      .card-rj {
        background-color: #fbf8ff;
      }
      .card-linktown {
        background-color: #ffc48c;
      }
      .card-man {
        background-color: #8dd3ff;
      }
      .card-intipesona {
        background-color: #ffb7c7;
      }
    }
  }
  .service-pricing {
    display: flex;
    padding: 2.5rem 2rem 0 2rem;
    position: relative;

    @media (max-width: 599px) {
      padding: 2.5rem 1rem 0 1rem;
    }
    .container-pricing {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      background-color: #690005;
      width: 80vw;
      border-radius: 32px;
      position: relative;
      align-items: center;
      justify-content: center;
      z-index: 1;

      @media (max-width: 1239px) {
        width: 90vw;
      }
      @media (max-width: 599px) {
        border-radius: 16px;
      }
      .line-1 {
        position: absolute;
        top: -90px;
        left: -60px;
        z-index: 2;
        @media (max-width: 1239px) {
          top: -100px;
          left: -50px;
        }
      }
      .table-pricing {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 880px;
        gap: 20px;
        align-items: center;
        justify-content: center;
        z-index: 4;
        padding: 4.063rem 0 2.5rem 0;
        @media (max-width: 1239px) {
          width: 90%;
        }
        @media (max-width: 599px) {
          padding: 2.875rem 0;
          gap: 7px;
        }
        .pricing-header {
          width: 100%;
          z-index: 1000000000;
          position: sticky;
          top: 70px;
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 1fr;
          justify-content: space-between;
          font-family: "Jost", sans-serif;
          border-bottom: 1px solid #fbf8ff;
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-radius: 9px 9px 0 0;

          @media (max-width: 1239px) {
            grid-template-columns: 1fr 1fr 1fr;
          }
          @media (max-width: 599px) {
            grid-template-columns: 1fr 1fr;
            :nth-child(4) {
              display: none;
            }
            gap: 10px;
            margin-bottom: 11px;
          }

          .item-head {
            @media (max-width: 1239px) {
              display: none;
            }
            .item-text {
              font-size: 19px;
              color: #e4e1e9;
              font-weight: 400;
            }
          }

          .content-head {
            padding: 0 15px;
            @media (max-width: 599px) {
              padding: 0;
            }

            .content-box {
              position: relative;
              background-color: #ffffff;
              padding: 9px;
              border-radius: 9px;
              text-align: center;
              .title {
                font-size: 19px;
                margin: 0;
                color: #1b1b21;
                font-weight: 400;
                @media (max-width: 599px) {
                  font-size: 19px;
                }
              }
              .caption {
                font-size: 14px;
                margin: 0 0 6px 0;
                color: #131318e3;
                font-weight: 400;
                @media (max-width: 599px) {
                  font-size: 12px;
                }
              }
              .price {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #525a92;
                gap: 2px;
                .currency {
                  font-size: 16px;
                  font-weight: 400;
                  margin: 2px 0;
                }
                .value {
                  font-size: 28px;
                  margin: 0;
                  font-weight: 400;
                  @media (max-width: 599px) {
                    font-size: 22px;
                  }
                }
              }
            }
            .dropdown-button {
              display: flex;
              width: 100%;
              justify-content: center;
              background-color: transparent;
              border: none;
              gap: 17px;
              padding: 0;
              font-family: "Jost", sans-serif;
              margin-bottom: 6px;
              @media (max-width: 599px) {
                justify-content: space-between;
              }

              .icon {
                display: none;
                @media (max-width: 599px) {
                  display: block;
                }
              }
            }

            .dropdown-content {
              opacity: 0;
              visibility: hidden;
              position: absolute;
              background-color: #f9f9f9;
              min-width: 160px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 4;
              transition: all 0.25s ease-out;
            }
            .dropdown-content.show {
              opacity: 1;
              visibility: visible;
            }

            .pricebutton {
              margin: 9px 0 0 0;
              width: 100%;
              .buttonwa {
                display: flex;
                background-color: #95cdf7;
                gap: 15px;
                width: 100%;
                height: 41px;
                padding: 9px 13px;
                justify-content: center;
                border-radius: 9px;
                border: none;
                color: #00344e;
                overflow: hidden;
                font: 16px "Jost", sans-serif;
                line-height: 23.12px;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                &:hover {
                  > div {
                    display: flex;
                    flex-direction: column;
                    .primary-arrow {
                      transform: translate3d(130%, -150%, 0);
                    }
                    .secondary-arrow {
                      transform: translate3d(0%, -150%, 0);
                    }
                  }
                }
                > div {
                  display: flex;
                  gap: 10px;
                  flex-direction: column;
                  height: 100px;
                  .primary-arrow {
                    transition: all 0.25s ease-out;
                  }
                  .secondary-arrow {
                    transition: all 0.25s ease-out;
                    transform: translate3d(-130%, 0%, 0);
                  }
                }
              }
            }
          }
        }

        .pricing-item {
          width: 100%;
          display: grid;
          grid-template-columns: 1.2fr 1fr 1fr 1fr;
          justify-content: space-between;
          font-family: "Jost", sans-serif;
          border-bottom: 1px solid #e4e1e940;
          padding: 0 0 9px 0;
          position: relative;
          @media (max-width: 1239px) {
            grid-template-columns: 1fr 1fr 1fr;
          }
          @media (max-width: 599px) {
            grid-template-columns: 1fr 1fr;
            :nth-child(4) {
              display: none;
            }
            padding: 0 0 7px 0;
          }
          .item-text {
            font-size: 19px;
            color: #e4e1e9;
            font-weight: 400;
            @media (max-width: 1239px) {
              position: absolute;
            }
          }
          .item-content {
            font-size: 19px;
            color: #e4e1e9;
            font-weight: 400;
            text-align: center;
            @media (max-width: 1239px) {
              padding-top: 40px;
            }
            @media (max-width: 599px) {
              padding-top: 30px;
            }
            .icon {
              width: 27px;
              height: 27px;
            }
          }
        }

        .disclaimer {
          font-family: "Jost", sans-serif;
          font-size: 14px;
          color: #e4e1e9ad;
          text-align: center;
        }
      }
      .mobile {
        display: none;
        @media (max-width: 599px) {
          display: block;
        }
        .headertitle {
          display: flex;
          flex-direction: column;
          position: relative;
          select {
            border: none;
            font-family: "Jost";
            text-transform: uppercase;
            background-color: #ffffff;
            &:focus {
              outline: none;
            }
          }
          .headerbox {
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            padding: 9px;
            border-radius: 9px;
            text-align: center;
          }
          .caption {
            font-size: 14px;
            margin: 0 0 6px 0;
            color: #131318e3;
            font-weight: 400;
            @media (max-width: 599px) {
              font-size: 12px;
            }
          }
          .price {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #525a92;
            gap: 2px;
            .currency {
              font-size: 16px;
              font-weight: 400;
              margin: 2px 0;
            }
            .value {
              font-size: 28px;
              margin: 0;
              font-weight: 400;
              @media (max-width: 599px) {
                font-size: 22px;
              }
            }
          }
          .pricebutton2 {
            margin: 9px 0 0 0;
            width: 100%;
            .buttonwa {
              display: flex;
              background-color: #95cdf7;
              gap: 15px;
              width: 100%;
              height: 41px;
              padding: 9px 13px;
              justify-content: center;
              border-radius: 9px;
              border: none;
              color: #00344e;
              overflow: hidden;
              font: 14px "Jost", sans-serif;
              line-height: 23.12px;
              font-weight: 500;
              cursor: pointer;
              text-align: center;
              &:hover {
                > div {
                  display: flex;
                  flex-direction: column;
                  .primary-arrow {
                    transform: translate3d(130%, -150%, 0);
                  }
                  .secondary-arrow {
                    transform: translate3d(0%, -150%, 0);
                  }
                }
              }
              > div {
                display: flex;
                gap: 10px;
                height: 100px;
                flex-direction: column;
                .primary-arrow {
                  transition: all 0.25s ease-out;
                }
                .secondary-arrow {
                  transition: all 0.25s ease-out;
                  transform: translate3d(-130%, 0%, 0);
                }
              }
            }
          }
        }
        .header {
          font-family: "Jost", sans-serif;
          z-index: 1000000000;
          position: sticky;
          top: 80px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          border-bottom: 1px solid #fbf8ff;
          gap: 10px;
          padding-bottom: 6px;
          margin-bottom: 11px;
          border-radius: 9px 9px 0 0;
          justify-content: space-between;
        }
        .pricing-item {
          width: 100%;
          display: grid;
          grid-template-columns: 1.2fr 1fr 1fr 1fr;
          justify-content: space-between;
          font-family: "Jost", sans-serif;
          border-bottom: 1px solid #e4e1e940;
          padding: 0 0 9px 0;
          position: relative;
          @media (max-width: 1239px) {
            grid-template-columns: 1fr 1fr 1fr;
          }
          @media (max-width: 599px) {
            grid-template-columns: 1fr 1fr;
            :nth-child(4) {
              display: none;
            }
            padding: 0 0 7px 0;
          }
          .item-text {
            font-size: 19px;
            color: #e4e1e9;
            font-weight: 400;
            @media (max-width: 1239px) {
              position: absolute;
            }
          }
          .item-content {
            font-size: 19px;
            color: #e4e1e9;
            font-weight: 400;
            text-align: center;
            @media (max-width: 1239px) {
              padding-top: 40px;
            }
            @media (max-width: 599px) {
              padding-top: 30px;
            }
            .icon {
              width: 27px;
              height: 27px;
            }
          }
        }
      }
      .desktop {
        @media (max-width: 599px) {
          display: none;
        }
      }

      .line-2 {
        position: absolute;
        bottom: -130px;
        right: -180px;
        z-index: 2;
        @media (max-width: 1239px) {
          right: -180px;
          bottom: -135px;
        }
      }
    }
  }
}
