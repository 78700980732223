.primary-button-dark {
  display: flex;
  padding: 9px;
  width: 100%;
  color: #ffffff;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
  font-family: "jost", sans-serif;
  font-weight: 500;
  height: 105px;
  background-color: #004769;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 9px;

  div {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 25px;
    transition: all 0.25s ease-out;
    height: 100px;

    .primary-arrow {
      transition: all 0.25s ease-out;
    }
    .secondary-arrow {
      transition: all 0.25s ease-out;
      transform: translate3d(-150%, 0, 0);
    }
  }
}
.primary-button-dark:hover {
  div {
    overflow: hidden;
    .primary-arrow {
      transition: all 0.25s ease-out;
      transform: translate3d(200%, -250%, 0);
    }
    .secondary-arrow {
      transition: all 0.25s ease-out;
      transform: translate3d(0, -160%, 0);
    }
  }
}
